import { Box, Center, Flex, Icon, Text } from '@chakra-ui/react'
import { LucideIcon } from 'lucide-react'
import { IconType } from 'react-icons'

interface Props {
  title: string
  description: string
  icon: IconType | LucideIcon
}

export default function Card({ title, icon, description }: Props) {
  if (!icon) return null

  return (
    <Flex
      flex={{ base: 'auto', lg: 1 }}
      w={{ base: '100%', lg: 'auto' }}
      maxW={{ base: '600px', lg: '380px' }}
      mx="auto"
      flexDir={'column'}
      gap={{ base: 4, sm: 5, md: 6 }}
      as={'article'}
      align={'center'}
      position="relative"
      py={{ base: 4, sm: 5, md: 6, lg: 16 }}
      px={{ base: '4', sm: '6', md: '8', lg: '14' }}
      borderRadius={24}
      overflow="hidden"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgColor={'rgba(56,92,169, 0.5)'}
        zIndex={1}
      />

      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgImage={`url(https://d1k1f4n2h095ym.cloudfront.net/bg_box.jpeg)`}
        bgSize={'cover'}
        bgPosition={'center'}
        opacity={0.8}
        zIndex={0}
      />

      <Flex
        position="relative"
        zIndex={2}
        flexDir={'column'}
        gap={{ base: 3, sm: 4 }}
        justifyContent={'center'}
        alignItems="center"
        w="100%"
      >
        <Center rounded={'md'}>
          <Icon as={icon} boxSize={100} strokeWidth={1} />
        </Center>
        <Text
          fontWeight={'semibold'}
          fontSize={{ base: 'lg', sm: 'xl' }}
          textAlign={'center'}
          as={'h1'}
          color="white"
        >
          {title}
        </Text>
        <Text
          fontSize={'sm'}
          textAlign={'center'}
          color="white"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Flex>
    </Flex>
  )
}
